<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-drawer
      title="Nouveau Médecin"
      :width="400"
      :visible="doctorFormDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="hideDoctorFormDrawer"
    >
      <a-form :form="newDrForm" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="18">
            <a-form-item label="Matricule">
              <a-input
                v-decorator="[
                  'matricule',
                  {
                    rules: [
                      { required: true, message: 'Matricule est obligatoire' },
                    ],
                  },
                ]"
                placeholder="12S/100-N"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="18">
            <a-form-item label="Date d'installation">
              <a-date-picker
                :format="dateFormat"
                style="width: 100%"
                v-decorator="[
                  'dt_inscription',
                  {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Date  est obligatoire!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="18">
            <a-form-item label="Nom Complet">
              <a-input
                v-decorator="[
                  'full_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Nom complet est obligatoire',
                      },
                    ],
                  },
                ]"
                placeholder="Nom Prénom"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="18">
            <a-form-item label="Spécialité ">
              <a-select
                  v-decorator="[
                  'specialite_id',
                  {
                    rules: [
                      { required: true, message: 'La spécialité est obligatoire' },
                    ],
                  },
                ]"
                  placeholder="Il faut sélectionnner une Spécialité"
              >
                  <a-select-option
                      v-for="specialiteMedecin in specialitesMedecin"
                      :key="specialiteMedecin.id"
                  >
                      {{ specialiteMedecin.nom_fr }}
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="hideDoctorFormDrawer">
          Annuler
        </a-button>
        <a-button type="primary" @click="handleMedecinFormSubmit">Sauvegarder</a-button>
      </div>
    </a-drawer>
    <template slot="footer">
      <a-button 
        :loading="processing"
        @click="handleCancel"
        >
        Annuler
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >
        Valider
      </a-button>
    </template>
    <a-form
      :layout="formLayout"
      id="etat_activite_form"
      name="etat_activite_form"
      class="adherent_form"
      :form="form"
    >
      <a-form-item v-bind="formItemLayout" label="Type ">
        <a-select
          @change="handleTypeDemandeChange"
          v-decorator="[
            'type_demande_id',
            {
              rules: [
                {
                  required: true,
                  message: 'Type de demande  est obligatoire!',
                },
                {
                  validator: typeDefaultValueIsnotPermited,
                },
              ],
            },
          ]"
          placeholder="Choisir  un type"
        >
          <a-select-option :key="0">Choisir un type</a-select-option>
          <a-select-option
            :key="typeDemande.code"
            v-for="typeDemande in typeDemandes"
          >
            {{ typeDemande.nom_fr }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Date demande" v-bind="formItemLayout">
        <a-date-picker
          :format="dateFormat"
          style="width: 100%"
          v-decorator="[
            'dt_demande',
            {
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Date   est obligatoire!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
 
      <a-collapse :bordered="false" v-if="showMembersField">
        <a-collapse-panel
          key="1"
          :show-arrow="true"
 
          style="
            background: #fff;
            border-radius: 4px;
            border: 0;
            overflow: hidden;
          "
        >
        <div slot="header">
             
        <a-button type="primary" v-if="showMembersField" @click="showDoctorFormDrawer">
          <a-icon type="plus" />
          Nouveau medecin
        </a-button>
        </div>
          <a-list item-layout="horizontal" :data-source="medecinList">
        <a-list-item slot="renderItem" slot-scope="item, index">
           <a slot="actions" @click="onDeleteMedecin(item.id)">Supp.</a>
          <a-list-item-meta :description="medecinSummary(item)">
            <p slot="title">{{ item.full_name }}</p>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
        </a-collapse-panel>
      </a-collapse>

      <a-form-item
        v-if="showMembersField"
        v-bind="formItemLayout"
        label="Membres dentistes :"
      >
        <a-select
          mode="multiple"
          :filter-option="filterOption"
          option-filter-prop="children"
          style="width: 100%"
          show-search
          placeholder="Destinataires"
          v-decorator="[
            'members',
            {
              rules: [
                {
                  required: isMembersFieldMandatory,
                  message: 'Membres de groupe est obligatoire!',
                },
                { validator: controlMax },
              ],
            },
          ]"
        >
          <a-select-option v-for="user in filteredUsers" :key="user.id">
            {{ user.full_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        v-bind="formItemLayout"
        label="Adresse précédente:"
        v-if="showOldPlaceAdresse"
      >
        <a-input
          v-decorator="[
            'adresse_precedent',
            {
              rules: [
                {
                  required: showOldPlaceAdresse,
                  message: 'Adresse précédente est obligatoire!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        v-bind="formItemLayout"
        label="Adresse précédente (Ar):"
        v-if="showOldPlaceAdresse"
      >
        <a-input
          v-decorator="[
            'adresse_precedent_ar',
            {
              rules: [
                {
                  pattern: /[\u0600-\u06FF]/,
                  message: 'Le format n\'est pas valide ',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        v-bind="formItemLayout"
        label="Wilaya précédente"
        v-if="showOldPlaceAdresse"
      >
        <a-select
          @change="handlePrecedentWilayasChange"
          v-decorator="[
            'wilaya_precedent_id',
            {
              rules: [
                {
                  required: showOldPlaceAdresse,
                  message: 'Wilaya précédente est obligatoire!',
                },
                {
                  validator: wilayaDeRegionDefaultValueIsnotPermited,
                },
              ],
            },
          ]"
          placeholder="Choisir  une wilaya"
        >
          <a-select-option :key="0">Choisir une wilaya</a-select-option>
          <a-select-option v-for="wilaya in listWilayas" :key="wilaya.id">
            {{ wilaya.nom_fr }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        v-bind="formItemLayout"
        label="Commune précédente"
        v-if="showOldPlaceAdresse"
      >
        <a-select
          :filter-option="filterCommunePrecedent"
          option-filter-prop="children"
          show-search
          v-decorator="[
            'commune_precedent_id',
            {
              rules: [
                {
                  required: showOldPlaceAdresse,
                  message: 'Commune precedent  est obligatoire!',
                },
                {
                  validator: communePrecedentDeRegionDefaultValueIsnotPermited,
                },
              ],
            },
          ]"
          placeholder="Choisir  une commune"
        >
          <a-select-option :key="0">Choisir une commune</a-select-option>
          <a-select-option
            v-for="commune in local_communes_precedent"
            :key="commune.id"
          >
            {{ commune.nom_fr }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Wilaya d'ouverture">
        <a-select
          @change="handleWilayasChange"
          v-decorator="[
            'wilaya_id',
            {
              rules: [
                {
                  required: true,
                  message: 'Wilaya  est obligatoire!',
                },
                {
                  validator: wilayaDeRegionDefaultValueIsnotPermited,
                },
              ],
            },
          ]"
          placeholder="Choisir  une wilaya d'ouverture"
        >
          <a-select-option :key="0">Choisir une wilaya</a-select-option>
          <a-select-option v-for="wilaya in listWilayas" :key="wilaya.id">
            {{ wilaya.nom_fr }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Commune d'ouverture">
        <a-select
          :filter-option="filterCommune"
          option-filter-prop="children"
          show-search
          v-decorator="[
            'commune_id',
            {
              rules: [
                {
                  required: true,
                  message: 'Commune  est obligatoire!',
                },
                {
                  validator: communeDeRegionDefaultValueIsnotPermited,
                },
              ],
            },
          ]"
          placeholder="Choisir  une commune d'ouverture"
        >
          <a-select-option :key="0">Choisir une commune</a-select-option>
          <a-select-option v-for="commune in local_communes" :key="commune.id">
            {{ commune.nom_fr }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Adresse:">
        <a-input
          v-decorator="[
            'adresse',
            {
              rules: [
                {
                  required: true,
                  message: 'Adresse  est obligatoire!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Adresse (Ar):">
        <a-input
          v-decorator="[
            'adresse_ar',
            {
              rules: [
                {
                  pattern: /[\u0600-\u06FF]/,
                  message: 'Le format n\'est pas valide ',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Superficie :">
        <a-input-number
          :min="50"
          :formatter="(value) => `${value}m²`"
          :parser="(value) => value.replace('m²', '')"
          v-decorator="[
            'superficie',
            {
              rules: [
                {
                  required: true,
                  message: 'Superficie est obligatoire!',
                },
              ],
            },
          ]"
        />
        (Min est 50m².)
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="Tél.:">
        <a-input
          v-decorator="[
            'tel',
            {
              rules: [
                {
                  required: true,
                  message: 'Tél.  est obligatoire!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item v-bind="{ xs: { span: 24 }, sm: { span: 24 } }">
        <a-checkbox
          v-decorator="[
            'is_ansej',
            {
              valuePropName: 'checked',
              initialValue: get(demande, 'is_ansej', false),
            },
          ]"
        >
          Engagement avec l'Agence Nationale de Soutien à l'Emploi des Jeunes
          (ANSEJ)
        </a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import _filter from "lodash-es/filter";
import _map from "lodash-es/map";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export default {
  name: "DemandesOuverturesEditor",
  created() {
    this.getExtraData();
  },
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout,
      showOldPlaceAdresse: true,
      showMembersField: true,
      doctorFormDrawerVisible: false,
      local_communes: [],
      lastMedecinId : 0,
      local_communes_precedent: [],
      form: this.$form.createForm(this),
      newDrForm: this.$form.createForm(this),
    };
  },
  props: {
    visible: { type: Boolean, default: () => false },
    processing: { type: Boolean, default: () => false },
    demande: { type: Object },
    currentUser: { type: Object },
    users: { type: Array, default: () => [] },
    medecinList : {type : Array, default : () => []}
  },
  computed: {
    ...mapState({
        secteurs: (state) => state.secteurs.secteurs,
        statuts_professionels: (state) => state.statuts.statuts,
        listWilayas: (state) => state.wilayas.organisation_wilayas,
        specialitesMedecin: (state) => state.specialites_medecin.specialites_medecin,
        targetKeys: (state) => state.organisation.listWilayasKeys,
        wilayas: (state) => state.wilayas.wilayas,
        communes: (state) => state.wilayas.organisation_communes,
    }),
      ...mapGetters({
          typeDemandes: "getTypeDemandes",
      }),
      isMembersFieldMandatory() {
          return this.showMembersField && !this.medecinList.length
      },
      title() {
          return this.demande
              ? `Modifier - Responsable
                 ${this.responsable}`
              : `Nouvelle demande`;
      },
      filteredUsers() {
          if (!this.currentUser) {
              return this.users;
          }
      return this.users.filter((u) => u.id !== this.currentUser.id);
    },
    responsable() {
      return this.get(this, "demande.user")
        ? `${this.demande.user.nom_fr} ${this.demande.user.prenom_fr}`
        : "";
    },
  },
  methods: {
    medecinSummary(medecin) {
      return `Matricule : ${medecin.matricule}, Dt. Inscription :${medecin.dt_inscription}`;
    },
    showDoctorFormDrawer() {
      this.doctorFormDrawerVisible = true
    },
    hideDoctorFormDrawer() {
      this.doctorFormDrawerVisible = false
    },
    filterCommunePrecedent(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterCommune(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    controlMax(rule, value, callback) {
      if (value && value.length > 6) {
        callback("Pas plus de six membres!!");
        return;
      }
      callback();
    },
    getExtraData() {
      this.$store.cache.dispatch("fetchWilayas");
      this.$store.cache.dispatch("fetchOrganisationCommunes");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
        this.$store.cache.dispatch("fetchTypeDemandes");
        this.$store.cache.dispatch("fetchSpecialitesMedecin");
    },
    handleWilayasChange(value) {
      this.form.setFieldsValue({ commune_id: 0 });
      this.local_communes = this.communes.filter((item) => {
        return item.wilaya_id === value;
      });
    },
    handlePrecedentWilayasChange(value) {
      this.form.setFieldsValue({ commune_precedent_id: 0 });
      this.local_communes_precedent = this.communes.filter((item) => {
        return item.wilaya_id === value;
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleOk() {
      this.$emit("create");
    },
    handleMedecinFormSubmit() {
      const form = this.newDrForm;

      form.validateFields((err, values) => {
        if (!err) {
          values["dt_inscription"] = this.formatDate(
            values["dt_inscription"],
            "YYYY/MM/DD"
          )
          this.lastMedecinId++
          values['id'] =  this.lastMedecinId
          this.medecinList.push({ ...values })
          this.hideDoctorFormDrawer()
        }
      });
    },
    setLocalCommunes(userWilayaId) {
      this.local_communes = this.communes.filter((item) => {
        return item.wilaya_id === userWilayaId;
      });
    },
    setLocalCommunesPrecedent(userWilayaId) {
      this.local_communes_precedent = this.communes.filter((item) => {
        return item.wilaya_id === userWilayaId;
      });
    },
    fillTheForm() {
      if (this.demande) {
        this.$nextTick(() => {
          this.showOldPlaceAdresse = this.isDemandeOuvertureTransfert(
            this.get(this.demande, "type.code")
          );
          this.showMembersField = this.isDemandeOuvertureCabinetGroupe(
            this.get(this.demande, "type.code")
          );
          if (this.showOldPlaceAdresse) {
            this.setLocalCommunesPrecedent(this.demande.wilaya_precedent.id);
            this.form.setFieldsValue({
              commune_precedent_id: this.demande.commune_precedent.id,
            });
            this.form.setFieldsValue({
              wilaya_precedent_id: this.demande.wilaya_precedent.id,
            });
          }
          this.form.setFieldsValue(this.demande);
          this.form.setFieldsValue({
            dt_demande: this.momentDate(this.demande.dt_demande),
          });
          this.setLocalCommunes(this.demande.wilaya.id);
          this.form.setFieldsValue({ commune_id: this.demande.commune.id });
          this.form.setFieldsValue({ wilaya_id: this.demande.wilaya.id });
          this.form.setFieldsValue({ members: this.getMembersIDs() });
          this.form.setFieldsValue({
            type_demande_id: this.get(this.demande, "type.code"),
          });
        });
      }
    },
    handleTypeDemandeChange(value) {
      this.showOldPlaceAdresse = this.isDemandeOuvertureTransfert(value);
      this.showMembersField = this.isDemandeOuvertureCabinetGroupe(value);
    },
    onDeleteMedecin(key){
      this.$emit('remove_medecin', key)
    },
    getMembersIDs() {
      let members = _filter(this.demande.members, { responsable: false });
      return _map(members, (value) => value.id);
    },
    wilayaDeRegionDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("wilaya_id"),
        "Wilaya est obligatoire !"
      );
    },
    typeDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("type"),
        "Type est obligatoire !"
      );
    },
    communeDeRegionDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("commune_id"),
        "Commune est obligatoire !"
      );
    },
    communePrecedentDeRegionDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("commune_precedent_id"),
        "Commune est obligatoire !"
      );
    },
    checkIfDateIsInferiorThanDateInstallation(rule, value, callback) {
      if (this.momentDate(this.dateInstallation) >= this.momentDate(value)) {
        callback("La date doit être supérieur à la date d'installation");
        return;
      }
      callback();
    },
  },
};
</script>
